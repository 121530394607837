import { IColumn } from '../interfaces/table';
import { IAdminPermission, IObjectArray } from '../interfaces/user';
import { Permissions, Plans } from '../enums';

export const pageNavigationData = [
  { pageName: 'Accounts Management', navigateTo: '/account-management', key: 'accountsManagement' },
  { pageName: 'User Management', navigateTo: '/user-management', key: 'userManagement' },
  { pageName: 'Plan Management', navigateTo: '/plan-management', key: 'planManagement' },
  { pageName: 'Notification/Requests', navigateTo: '/notification-requests', key: 'notification' },
];

export const months: IObjectArray[] = [
  { id: 'Jan', name: 'January' },
  { id: 'Feb', name: 'February' },
  { id: 'Mar', name: 'March' },
  { id: 'Apr', name: 'April' },
  { id: 'May', name: 'May' },
  { id: 'Jun', name: 'June' },
  { id: 'Jul', name: 'July' },
  { id: 'Aug', name: 'August' },
  { id: 'Sep', name: 'September' },
  { id: 'Oct', name: 'October' },
  { id: 'Nov', name: 'November' },
  { id: 'Dec', name: 'December' },
];

export const getManagementTitle = (isHome: boolean) => {
  if (isHome) {
    return [
      { id: 'name', label: 'Name' },
      { id: 'email', label: 'Email' },
      { id: 'phone', label: 'Phone' },
      { id: 'subscriptionType', label: 'Subscription Type' },
      { id: 'subscriptionStartDate', label: 'Subscription Start Date' },
      { id: 'subscriptionEndDate', label: 'Subscription End Date' },
      { id: 'status', label: 'Status' },
    ];
  }
  return [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Phone' },
    { id: 'subscriptionType', label: 'Subscription Type' },
    { id: 'subscriptionStartDate', label: 'Subscription Start Date' },
    { id: 'subscriptionEndDate', label: 'Subscription End Date' },
    { id: 'status', label: 'Status' },
    { id: 'sendMail', label: 'Send Mail' },
    { id: 'loginAs', label: 'Login As' },
    { id: 'deactivation', label: 'Deactivation' },
    { id: 'action', label: 'Action' },
  ];
};

export const accountManagementColumns: readonly IColumn[] = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'phone', label: 'Phone' },
  { id: 'subscriptionType', label: 'Subscription Type' },
  { id: 'subscriptionStartDate', label: 'Subscription Start Date' },
  { id: 'subscriptionEndDate', label: 'Subscription End Date' },
  { id: 'status', label: 'Status' },
  { id: 'sendMail', label: 'Send Mail' },
  { id: 'action', label: 'Action' },
];

export const planManagementColumns: readonly IColumn[] = [
  { id: 'planName', label: 'Plan Name' },
  { id: 'planType', label: 'Plan Type' },
  { id: 'planAmount', label: 'Plan Amount' },
  { id: 'action', label: 'action' },
];

export const subscribedUsersColumns: readonly IColumn[] = [
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'phone', label: 'Phone' },
  { id: 'status', label: 'Status' },
];

export const plans: IObjectArray[] = [
  { id: Plans.ALL, name: Plans.ALL },
  { id: Plans.FREE_ID, name: Plans.FREE },
  { id: Plans.SOLO_ID, name: Plans.INDIVIDUAL },
  { id: Plans.BUSINESS_ID, name: Plans.BUSINESS },
  { id: Plans.CUSTOM_ID, name: Plans.CUSTOM },
];

export const DefaultPermissions: IAdminPermission = {
  [Permissions.ACCOUNTS_MANAGEMENT]: false,
  [Permissions.NOTIFICATION]: false,
  [Permissions.PLAN_MANAGEMENT]: false,
  [Permissions.USER_MANAGEMENT]: false,
};
