import { AxiosInstance } from 'axios';
import { IAPIResponse } from '../interfaces';

const adminService = (httpClient: AxiosInstance) => {
  return {
    changePassword: async (oldPassword: string, newPassword: string) => {
      const data = { oldPassword, newPassword };
      return httpClient
        .post<IAPIResponse>(`change-password`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Invalid email or password', data: [] };
        });
    },
  };
};

export default adminService;
