import { FC, ReactNode } from 'react';
import { PermissionContext } from '../context';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IAdminPermission } from '../interfaces/user';
import { userService } from '../service';
import { DefaultPermissions } from '../utils/constants';
import { UnAuthenticatedRoutes } from '../routes';

interface PermissionProviderProps {
  children?: ReactNode;
}

const PermissionProvider: FC<PermissionProviderProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState<IAdminPermission>(DefaultPermissions);

  const getPermissions = () => {
    userService.getAdminPermission().then(async (res) => {
      if (!res.status || !res.data) {
        toast.error(res.message);
        return;
      }

      setPermissions(res.data);
      await navigate(location.pathname);
    });
  };

  useEffect(() => {
    if (Object.values(UnAuthenticatedRoutes).includes(location.pathname.split('/')[1])) {
      return;
    }

    getPermissions();
  }, []);

  return <PermissionContext.Provider value={permissions}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
