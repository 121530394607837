import { AxiosInstance } from 'axios';
import { IAPIResponse } from '../interfaces';
import { IAccount, IAccountCounts, IAccountUser, IAdminPermission, IPermission, IRevenuePerMonth, IRevenuePerYear, IUser, IUserCount } from '../interfaces/user';

const userService = (httpClient: AxiosInstance) => {
  return {
    getUsers: async () => {
      return httpClient
        .get<IAPIResponse<IUser[]>>('/')
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: [] };
        });
    },

    updateUser: async (id: string, data: {}) => {
      return httpClient
        .patch<IAPIResponse>(`${id}`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: [] };
        });
    },

    createUser: async (data: {}) => {
      return httpClient
        .post<IAPIResponse>('/create-organization', data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: [] };
        });
    },

    resendMail: async (id: string) => {
      return httpClient
        .patch<IAPIResponse>(`/resend-mail/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: [] };
        });
    },

    resendAdminMail: async (id: string) => {
      return httpClient
        .patch<IAPIResponse>(`/resend-mail/admin/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: [] };
        });
    },

    usersCount: async () => {
      return httpClient
        .get<IAPIResponse<IUserCount>>('/user-count')
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    revenuePerMonth: async (month: string) => {
      return httpClient
        .get<IAPIResponse<IRevenuePerMonth>>(`/revenue/?month=${month}&filter=month`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    revenuePerYear: async (year: string) => {
      return httpClient
        .get<IAPIResponse<IRevenuePerYear>>(`/revenue/?year=${year}&filter=year`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    getAccounts: async () => {
      return httpClient
        .get<IAPIResponse<IAccount[]>>(`/account-management`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    getAccountCounts: async (orgId: string) => {
      return httpClient
        .get<IAPIResponse<IAccountCounts>>(`/account-management/counts/${orgId}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    getPermissions: async () => {
      return httpClient
        .get<IAPIResponse<IPermission[]>>(`/info`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    getAdmins: async () => {
      return httpClient
        .get<IAPIResponse<IAccountUser[]>>(`/read`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    resetPasswordByAdmin: async (data: {}) => {
      return httpClient
        .put<IAPIResponse>('/organization/reset-password', data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: [] };
        });
    },

    createAdmin: async (data: {}) => {
      return httpClient
        .post<IAPIResponse>('/', data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: [] };
        });
    },

    deleteAdmin: async (id: string) => {
      return httpClient
        .delete<IAPIResponse>(`/${id}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    updateAdmin: async (id: string, data: {}) => {
      return httpClient
        .put<IAPIResponse>(`/${id}`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: [] };
        });
    },

    getAdminPermission: async () => {
      return httpClient
        .get<IAPIResponse<IAdminPermission>>('/permissions')
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get access.', data: undefined };
        });
    },

    LoginAs: async (data: {}) => {
      return httpClient
        .post<IAPIResponse<{ url: string }>>('/login-as', data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get access.', data: undefined };
        });
    },
  };
};

export default userService;
