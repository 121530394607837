import { IAuthToken } from '../interfaces';

const KEY = 'auth';

export const setAuthStorage = (auth: IAuthToken): void => {
  try {
    localStorage.setItem(KEY, JSON.stringify(auth));
    return;
  } catch (err) {
    return;
  }
};

export const getAuthStorage = (): IAuthToken | null => {
  try {
    const auth = localStorage.getItem(KEY);
    if (!auth) {
      return null;
    }
    const result = JSON.parse(auth);
    return result;
  } catch (err) {
    return null;
  }
};

export const clearStorage = (key = KEY) => {
  localStorage.removeItem(key);
};
