import { FC } from 'react';

import loading from './../../images/loading.gif';

import styles from './loader.module.css';

interface LoaderProps {}

const Loader: FC<LoaderProps> = () => {
  return (
    <div className={styles.loader_wrapper}>
      <img alt="loading" src={loading} className={styles.loader_image} />
    </div>
  );
};

export default Loader;
