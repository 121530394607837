import { IAddonsResponse, IPlanAndSubscriptionsRes } from './../interfaces/plan';
import { IAPIResponse } from '../interfaces';
import { IAddonsData, IPlans, ISubscribedUsers } from '../interfaces/plan';
import { AxiosInstance } from 'axios';

const planService = (httpClient: AxiosInstance) => {
  return {
    getPlans: async () => {
      return httpClient
        .get<IAPIResponse<IPlans[]>>('/plans')
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    getPlansById: async (id: string, type?: string) => {
      return httpClient
        .get<IAPIResponse<IPlans>>(`/plans/${id}?type=${type}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    customPlan: async (orgId: string, planId?: string, priceId?: string) => {
      const data = { planId, priceId };
      return httpClient
        .put<IAPIResponse>(`/custom-plan/${orgId}`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'custom update failed', data: null };
        });
    },

    deletePlan: async (id: string) => {
      return httpClient
        .delete<IAPIResponse>(`/custom-plan/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'custom update failed', data: null };
        });
    },

    getSubscribedUsers: async (planId: string, priceListId: string) => {
      return httpClient
        .get<IAPIResponse<ISubscribedUsers>>(`/subscribed-users/${planId}/${priceListId}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get users', data: undefined };
        });
    },

    createCustomPlan: async (data: {}) => {
      return httpClient
        .post<IAPIResponse>(`/custom-plan/`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Create failed.', data: null };
        });
    },

    updateCustomPlan: async (id: string, priceListId: string, data: {}) => {
      return httpClient
        .put<IAPIResponse>(`/custom-plan/edit/${id}/${priceListId}`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'Update failed.', data: null };
        });
    },

    getStorageAddonsData: async () => {
      return httpClient
        .get<IAPIResponse<IAddonsResponse<IAddonsData[]>>>(`/setting/addon?type=STORAGE`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    getPractitionerCountAddonsData: async () => {
      return httpClient
        .get<IAPIResponse<IAddonsResponse<IAddonsData>>>(`/setting/addon?type=PRACTITIONER_COUNT`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    createStorageAddons: async (data: {}) => {
      return httpClient
        .post<IAPIResponse>(`/setting/addon/storage`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'create failed', data: null };
        });
    },

    editStorageAddons: async (data: {}) => {
      return httpClient
        .put<IAPIResponse>(`/setting/addon/storage`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'create failed', data: null };
        });
    },

    deleteStorageAddons: async (id: string) => {
      return httpClient
        .delete<IAPIResponse>(`/setting/addon/storage/${id}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'create failed', data: null };
        });
    },

    editPractitionerAddons: async (data: {}) => {
      return httpClient
        .put<IAPIResponse>(`/setting/addon/practitioner-count`, data)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return { status: false, message: 'create failed', data: null };
        });
    },

    getPlansAndSubscriptions: async () => {
      return httpClient
        .get<IAPIResponse<IPlanAndSubscriptionsRes[]>>('/plans-subscriptions')
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },
  };
};

export default planService;
