import { AxiosInstance } from 'axios';
import { TicketStatus } from '../enums';
import { IAPIResponse } from '../interfaces';
import { ISupportTicket } from '../interfaces/support';

const supportService = (httpClient: AxiosInstance) => {
  return {
    getTickets: async (status?: string) => {
      return httpClient
        .get<IAPIResponse<ISupportTicket[]>>(`/support/tickets${status ? `?status=${status.toUpperCase()}` : ''}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    sendReply: async (id: string, data = {}) => {
      return httpClient
        .put<IAPIResponse>(`/support/reply/${id}`, data)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Please verify the details provided.', data: undefined };
        });
    },

    deleteReply: async (ticketId: string, replyId: string) => {
      return httpClient
        .delete<IAPIResponse>(`/support/reply/${ticketId}/${replyId}`)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to delete.', data: undefined };
        });
    },

    updateStatus: async (id: string, data: { status: string }) => {
      return httpClient
        .put<IAPIResponse>(`/support/status/${id}`, data)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update status.', data: undefined };
        });
    },

    getUnreadTicketCount: async (data = {}) => {
      return httpClient
        .get<IAPIResponse<{ count: number }>>('/support/tickets/count', data)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to get count.', data: undefined };
        });
    },

    readTicket: async (id: string, data = {}) => {
      return httpClient
        .put<IAPIResponse>(`/support/read-status/${id}`, data)
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          return { status: false, message: 'Failed to update read status.', data: undefined };
        });
    },
  };
};

export default supportService;
