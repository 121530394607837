import { Slide } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { Colors, SizeUnits } from './types';

declare module '@mui/material/styles' {
  interface CustomTheme {
    colors?:  Colors;
    fontSizes: SizeUnits;
  }
  
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

const theme = createTheme({
  palette: {
    primary: { main: '#000000' },
    secondary: { main: '#465D6E' },
  },
  colors: {
    primary: {
      '20': '#C1E0E720',
      '40': '#C1E0E740',
      '60': '#C1E0E760',
      '80': '#C1E0E780',
      default: '#C1E0E7',
    },
    secondary:{
      '20': '#3B3E4220',
      '40': '#3B3E4240',
      '60': '#3B3E4260',
      '80': '#3B3E4280',
      default: '#3B3E42',
    },
    lightStaleGrey: {
      '20': '#77889920',
      '40': '#77889940',
      '60': '#77889960',
      '80': '#77889980',
      default: '#778899',
    },
    aliceBlue: {
      '20': '#F0F8FF20',
      '40': '#F0F8FF40',
      '60': '#F0F8FF60',
      '80': '#F0F8FF80',
      default: '#F0F8FF',
    },
    lightGrey: {
      '20': '#D3D3D320',
      '40': '#D3D3D340',
      '60': '#D3D3D360',
      '80': '#D3D3D380',
      default: '#D3D3D3',
    },
    almond: {
      '20': '#EFDECD20',
      '40': '#EFDECD40',
      '60': '#EFDECD60',
      '80': '#EFDECD80',
      default: '#EFDECD',
    },
    davyGrey: {
      '20': '#55555520',
      '40': '#55555540',
      '60': '#55555560',
      '80': '#55555580',
      default: '#555555',
    },
    onyx: {
      '20': '#35383920',
      '40': '#35383940',
      '60': '#35383960',
      '80': '#35383980',
      default: '#353839',
    },
    charcoal: {
      '20': '#36454F20',
      '40': '#36454F40',
      '60': '#36454F60',
      '80': '#36454F80',
      default: '#36454F',
    },
    cadet: {
      '20': '#53687220',
      '40': '#53687240',
      '60': '#53687260',
      '80': '#53687280',
      default: '#536872',
    },
    error: {
      '20': '#E32B2420',
      '40': '#E32B2440',
      '60': '#E32B2460',
      '80': '#E32B2480',
      default: '#E32B24',
    },
    warning: {
      '20': '#FFC10720',
      '40': '#FFC10740',
      '60': '#FFC10760',
      '80': '#FFC10780',
      default: '#FFC107',
    },
    success: {
      '20': '#18BB0C20',
      '40': '#18BB0C40',
      '60': '#18BB0C60',
      '80': '#18BB0C80',
      default: '#18BB0C',
    },
    lightBlue: {
      '20': '#2680EB20',
      '40': '#2680EB40',
      '60': '#2680EB60',
      '80': '#2680EB80',
      default: '#2680EB',
    },
  },
  fontSizes: {
    h1: '34px',
    h2: '22px',
    h3: '20px',
    xl: '18px',
    large: '16px',
    medium: '14px',
    small: '12px',
  },
});

interface ThemesProps {
  children?: React.ReactNode;
}

const Themes: FC<ThemesProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        TransitionComponent={Slide as any}
        autoHideDuration={3000}
      >
        {props.children}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default Themes;
