import { Navigate } from 'react-router-dom';
import { usePermissionContext } from '../context';
import { IAdminPermission } from '../interfaces/user';
import { getAuthStorage } from '../utils/storage-util';

interface Props {
  component: React.ComponentType;
  path?: string;
  permissionProperty?: keyof IAdminPermission;
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent, permissionProperty }) => {
  const permissions = usePermissionContext();
  const isAuthenticated = getAuthStorage();

  const isAllowed = permissionProperty ? !!permissions[permissionProperty] : true;
  if (isAuthenticated && !isAllowed) {
    return <Navigate to="/" />;
  }

  if (isAuthenticated && isAllowed) {
    return <RouteComponent />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
