import { AxiosInstance, AxiosResponse } from 'axios';
import { IAPIResponse, IAuthToken } from '../interfaces';
import { setAuthStorage } from '../utils/storage-util';

interface AuthService {
  login: (email: string, password: string) => Promise<IAPIResponse>;
  forgotPassword: (email: string) => Promise<IAPIResponse>;
  resetPassword: (token: string, password: string) => Promise<IAPIResponse>;
}

const authService = (httpClient: AxiosInstance): AuthService => {
  return {
    login: async (email: string, password: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse<IAuthToken>> = await httpClient.post('/login', { email, password });
        if (res.data.data) {
          setAuthStorage(res.data.data);
        }

        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Invalid email or password' };
      }
    },
    forgotPassword: async (email: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.patch(`/forget-password/${email}`);
        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Password reset failed' };
      }
    },
    resetPassword: async (token: string, password: string): Promise<IAPIResponse> => {
      try {
        const res: AxiosResponse<IAPIResponse> = await httpClient.patch(`/reset-password/verify`, {
          token,
          password,
        });
        return res.data;
      } catch (err: any) {
        return { status: false, message: 'Password reset failed' };
      }
    },
  };
};
export default authService;
