import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { apiConfig } from '../config/api-config';
import { PrivateRoute } from './private-routes';
import { PermissionProvider } from '../Provider';
import { Permissions } from '../enums';

const Login = lazy(() => import('../pages/login/Login'));
const Home = lazy(() => import('../pages/home/Home'));
const AccountManagement = lazy(() => import('../pages/account-management/AccountManagement'));
const PlanManagement = lazy(() => import('../pages/plan-management/PlanManagement'));
const UserManagement = lazy(() => import('../pages/user-management/UserManagement'));
const AssignPlan = lazy(() => import('../pages/assign-plan/AssignPlan'));
const ForgotPassword = lazy(() => import('../pages/password/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/password/ResetPassword'));
const ChangePassword = lazy(() => import('../pages/settings/PasswordSetting'));
const NotificationRequest = lazy(() => import('../pages/notification-request/NotificationRequest'));

export const UnAuthenticatedRoutes = {
  login: 'login',
  signup: 'signup',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
};

const Routers = () => {
  return (
    <PermissionProvider>
      <Routes>
        <Route path={`${apiConfig.basePath}/${UnAuthenticatedRoutes.login}`} element={<Login />} />
        <Route path={`${apiConfig.basePath}/${UnAuthenticatedRoutes.signup}/:token`} element={<ResetPassword title="Sign Up" />} />
        <Route path={`${apiConfig.basePath}/${UnAuthenticatedRoutes.forgotPassword}`} element={<ForgotPassword />} />
        <Route path={`${apiConfig.basePath}/${UnAuthenticatedRoutes.resetPassword}/:token`} element={<ResetPassword title="Reset Password" />} />

        <Route path={`${apiConfig.basePath}`} element={<PrivateRoute component={Home} />} />
        <Route path={`${apiConfig.basePath}/account-management`} element={<PrivateRoute component={AccountManagement} permissionProperty={Permissions.ACCOUNTS_MANAGEMENT} />} />
        <Route path={`${apiConfig.basePath}/user-management`} element={<PrivateRoute component={UserManagement} permissionProperty={Permissions.USER_MANAGEMENT} />} />
        <Route path={`${apiConfig.basePath}/plan-management/:url`} element={<PrivateRoute component={PlanManagement} permissionProperty={Permissions.PLAN_MANAGEMENT} />} />
        <Route path={`${apiConfig.basePath}/plan-management/`} element={<PrivateRoute component={PlanManagement} permissionProperty={Permissions.PLAN_MANAGEMENT} />} />
        <Route path={`${apiConfig.basePath}/notification-requests`} element={<PrivateRoute component={NotificationRequest} permissionProperty={Permissions.NOTIFICATION} />} />
        <Route
          path={`${apiConfig.basePath}/plan-management/assignPlan/:planId/:priceListId`}
          element={<PrivateRoute component={AssignPlan} permissionProperty={Permissions.PLAN_MANAGEMENT} />}
        />
        <Route path={`${apiConfig.basePath}/change-password`} element={<PrivateRoute component={ChangePassword} />} />
        <Route path={`*`} element={<PrivateRoute component={Home} />} />
      </Routes>
    </PermissionProvider>
  );
};

export default Routers;
