import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Loader from './components/loader/Loader';
import Themes from './components/themes';

import Routers from './routes';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Themes>
            <Routers />
            <ToastContainer position="bottom-right" autoClose={2000} />
          </Themes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
