import axios, { AxiosRequestConfig } from 'axios';
import { apiConfig } from '../config/api-config';
import { clearStorage, getAuthStorage } from '../utils/storage-util';
import auth from './auth-service';
import user from './user-service';
import plan from './plan-service';
import admin from './settings-service';
import support from './support-service';

const httpClient = axios.create(apiConfig);

httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const auth = getAuthStorage();
  if (auth && config.headers) {
    config.headers.Authorization = `Bearer ${auth.token}`;
  }

  return config;
});

export const logout = () => {
  clearStorage();
  window.location.href = `/login`;
};

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err.message === 'Network Error') {
      return;
    }

    if (err.request.responseURL.includes('/api/admin/login')) {
      return;
    }

    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
      logout();
      return;
    }

    if (err.response.status === 403 && err.config && !err.config.__isRetryRequest) {
      logout();
      return;
    }

    throw err;
  },
);

const authService = auth(httpClient);
const userService = user(httpClient);
const adminService = admin(httpClient);
const planService = plan(httpClient);
const supportService = support(httpClient);

export { authService, userService, adminService, planService, supportService };
