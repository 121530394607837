import { createContext, useContext } from 'react';
import { IAdminPermission } from '../interfaces/user';

export const PermissionContext = createContext<IAdminPermission>({
  accountsManagement: false,
  notification: false,
  planManagement: false,
  userManagement: false,
});

export const usePermissionContext = () => useContext(PermissionContext);