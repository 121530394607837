export enum Plans {
  BUSINESS_ID = 'business',
  BUSINESS = 'Business',
  SOLO_ID = 'individual',
  INDIVIDUAL = 'Individual',
  FREE_ID = 'free',
  FREE = 'Free',
  ALL = 'All',
  ACTIVE = 'Active',
  CUSTOM = 'Custom',
  CUSTOM_ID = 'custom',
}

export enum Title {
  REVENUE_PER_MONTH = 'Revenue Per Month',
  SELECT_MONTH = 'Select Month',
  TOTAL_INCOME = 'Total Income',
  INCOME_ANALYTICS = 'Income Analytics',
  ACCOUNT='Accounts',
  ACCOUNT_MANAGEMENT = 'Account Management',
  SELECT_PLAN = 'Select Plans',
  PLAN_MANAGEMENT = 'Plan Management',
  USER_MANAGEMENT = 'User Management',
  PLANS = 'Plans',
  NOTIFICATION_REQUESTS = 'Notification Requests',
  MANAGE_PROFILE = 'reset password',
}

export enum AccountManagementStatus {
  ACTIVE = 'Active',
  PAYMENT_DUE = 'Payment Due',
  NOT_ACTIVATED = 'Not Activated',
}

export enum Amount {
  ZERO = '$0',
}

export enum PlanParamTypes {
  PRACTITIONER_COUNT = 'PRACTITIONER_COUNT',
  PATIENT_COUNT = 'PATIENT_COUNT',
  PATIENT_COUNT_PER_PRACTITIONER = 'PATIENT_COUNT_PER_PRACTITIONER',
  STORAGE = 'STORAGE',
  IMAGE_COUNT = 'IMAGE_COUNT',
  VIDEO_COUNT = 'VIDEO_COUNT',
}

export enum PlanParamUnits {
  MB = 'MB',
  COUNT = 'NUMBERS',
}

export enum SubscriptionStatus {
  ACTIVE = 'Active',
  MIGRATING = 'Migrating',
}

export enum Permissions {
  ACCOUNTS_MANAGEMENT = 'accountsManagement',
  NOTIFICATION = 'notification',
  PLAN_MANAGEMENT = 'planManagement',
  USER_MANAGEMENT = 'userManagement',
}

export enum TicketStatus {
  OPEN = 'open',
  CLOSE = 'close',
  PROCESSING = 'processing',
}

export enum AddonsType {
  PRACTITIONER_COUNT = 'PRACTITIONER_COUNT',
  STORAGE = 'STORAGE',
}
